import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loader from "react-loader-spinner";
import "./App.scss";

const SignIn = lazy(() => import("./Onboarding/SignIn/SignIn"));
const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));
const CreateIssues = lazy(() => import("./Pages/CreateIssues/CreateIssues"));
const Error404 = lazy(() => import("./Pages/Error404/Error404"));

class App extends Component {
  render() {
    return (
      <div className="app">
        <Router>
          <Suspense
            fallback={
              <Loader
                className="loader"
                type="Oval"
                color="#FFC91C"
                height={80}
                width={80}
                timeout={3000}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            }
          >
            <Switch>
              <Route path="/" exact component={SignIn} />
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/support" exact component={CreateIssues} />
              <Route path="*" component={Error404} />
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

export default App;
